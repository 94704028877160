import { areFilterValuesEqual, filterElements, getFilterDefault } from "PFApp/use_filtered_collection";
import { Button } from "PFComponents/button";
import { stringify } from "PFCore/helpers/use_deterministic_stringify";
import FilterCleanIcon from "PFIcons/filter-clean.svg";
import { FilterType, Meta } from "PFTypes";
import { PageTarget } from "PFTypes/saved_filters";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useSavedViewsContext } from "../saved_views_context/saved_views_context";
import { DynamicItemsContainer } from "./dynamic_items_container";
import { FilterChangeFn, FiltersItem } from "./filters_item";
import css from "./filters_preview.module.scss";
import { FilterWithChildrenFlag } from "./filters_preview.utils";
import { getFilterHash, useFiltersDisplayValues } from "./use_filters_display_values";

const SKIP_FILTER_TYPES: FilterType[] = [FilterType.Radio, FilterType.ToggleCheckbox];

type FiltersPreviewProps = {
  meta: Omit<Meta, "total">;
  filtersAreClear: boolean;
  clearFilters: (params?: any) => void;
  onFilterChange: FilterChangeFn;
  onMoreClicked: () => void;
  blockedList?: string[];
  blockedListChildren?: string[];
  style?: React.CSSProperties;
  reserveSpace?: boolean;
  fetchOptions?: any;
  viewsKey?: PageTarget | null;
  disabled?: boolean;
  filterSectionLabels?: { root: string; children: string };
};

const FiltersPreview = ({
  meta,
  filtersAreClear,
  clearFilters,
  onFilterChange,
  onMoreClicked,
  blockedList = [],
  blockedListChildren = [],
  style,
  fetchOptions,
  reserveSpace,
  viewsKey,
  disabled = false,
  filterSectionLabels
}: FiltersPreviewProps): React.ReactElement | null => {
  const { t } = useTranslation(["translation", "bookingModule"]);
  const { setSelectedViewId } = useSavedViewsContext(viewsKey);
  const actionsContainerRef = useRef<HTMLDivElement>(null);

  const filterElems: FilterWithChildrenFlag[] = filterElements(
    meta.filters,
    blockedList,
    blockedListChildren
  );

  const filterPreviewItems: FilterWithChildrenFlag[] = filterElems
    .filter(({ value }) => !!value)
    .filter((filter) => !SKIP_FILTER_TYPES.includes(filter.type))
    .filter((filter) => {
      const defaultValue = getFilterDefault(filter, meta);
      const isDefaultValue = defaultValue && areFilterValuesEqual(defaultValue, filter.value);
      return !isDefaultValue;
    });
  const filtersDisplayValuesMap = useFiltersDisplayValues({
    meta,
    fetchOptions,
    filterPreviewItems
  });

  const checksum = useMemo(
    () => stringify({ filterPreviewItems, ...(filterSectionLabels ? { filterSectionLabels } : {}) }),
    [filterPreviewItems, filterSectionLabels]
  );

  const handleClearFilters = () => {
    setSelectedViewId(null);
    clearFilters();
  };

  const items = useMemo(
    () =>
      filterPreviewItems
        .filter(({ hidden }) => !hidden)
        .filter((filter) => !!filtersDisplayValuesMap[getFilterHash(filter)])
        .map((filter, index) => {
          const filterValues = filtersDisplayValuesMap[getFilterHash(filter)]!;
          const values = [filterValues].flat();
          const isOR = filter.operator === "any";
          const label = isOR
            ? t("translation:filters.filtersSelectedOR", { count: values.length })
            : t("translation:filters.filtersSelected", { count: values.length });
          const displayValue = { id: filter.value, text: values.length > 1 ? label : values[0]?.text };
          const key = index + checksum + JSON.stringify(displayValue); // remount and recalculate in case any filter changed
          return (
            <FiltersItem
              key={key}
              filter={filter}
              onFilterChange={onFilterChange}
              displayValue={displayValue}
              viewsKey={viewsKey}
              disabled={disabled}
              filterSectionLabels={filterSectionLabels}
            />
          );
        }),
    [filterPreviewItems, checksum, filtersDisplayValuesMap, onFilterChange, viewsKey, t]
  );

  if (!filterPreviewItems.length || filterPreviewItems.every(({ hidden }) => hidden)) {
    return reserveSpace ? <div className={css.root} /> : null;
  }

  return (
    <div className={css.root} style={style}>
      <div ref={actionsContainerRef} className={css.filtersLabelContainer}>
        <span>{t("bookingModule:filters.label")}</span>
        {!filtersAreClear && (
          <Button kind="text" className={css.clear} onClick={handleClearFilters} disabled={disabled}>
            <FilterCleanIcon height={16} width={16} />
            <span>{t("bookingModule:filters.clearAll")}</span>
          </Button>
        )}
      </div>
      <div style={{ width: `calc(100% - ${actionsContainerRef.current?.clientWidth ?? 0}px)` }}>
        <DynamicItemsContainer items={items} onMoreClick={onMoreClicked} disabled={disabled} />
      </div>
    </div>
  );
};

export default FiltersPreview;
