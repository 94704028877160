import classNames from "classnames";
import { useAppContext } from "PFApp/app_context";
import useIsAuditPlannerPermitted from "PFApp/use_is_audit_planner_permitted";
import useIsMarketplacePermitted from "PFApp/use_is_marketplace_permitted";
import useIsWorkflowPermitted from "PFApp/use_is_workflow_permitted";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import useIsPermittedTo from "PFCore/helpers/use_is_permitted_to";
import { useCurrentAccount } from "PFCore/hooks/queries/account/use_current_account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile/use_current_profile";
import { APP_LAYOUT_ACTIONS } from "PFReducers/app_layout_reducer";
import { FeatureFlag, PermissionRule } from "PFTypes";
import { useState } from "react";

import useBookingModulePermissions from "../../use_booking_module_permissions";
import { AdminNavItem } from "./items/admin_nav_item";
import { AnalyticsNavItem } from "./items/analytics_nav_item";
import { AuditPlannerNavItem } from "./items/audit_planner_nav_item";
import { BookingNavItem } from "./items/booking_nav_item";
import { CustomLinksNavItem } from "./items/custom_links_nav_item";
import { FeedNavItem } from "./items/feed_nav_item";
import { MarketplaceNavItem } from "./items/marketplace_nav_item";
import { NavLogo } from "./items/nav_logo";
import { NewActivitiesNavItem } from "./items/new_activities_nav_item";
import { NotificationsNavItem } from "./items/notifications_nav_item";
import { ProfilesNavItem } from "./items/profile_nav_item";
import { SearchNavItem } from "./items/search_nav_item";
import { WorkflowNavItem } from "./items/workflow_nav_item";
import { YouNavItem } from "./items/you_nav_item";
import css from "./navbar.module.scss";
import { MenuTrigger } from "./parts/menu_trigger";
import { CommonNavItemProps } from "./parts/nav_item";

export const Navbar = () => {
  const [openKey, setOpenKey] = useState(null);

  const {
    dispatch,
    store: {
      appLayout: { navBarRevealed }
    }
  } = useAppContext();

  const { data: currentAccount } = useCurrentAccount();
  const { data: currentProfile } = useCurrentProfile();

  const closeSubMenu = () => {
    dispatch({ type: APP_LAYOUT_ACTIONS.APP_LAYOUT_HIDE_BACKDROP });
    dispatch({ type: APP_LAYOUT_ACTIONS.APP_LAYOUT_HIDE_NAV });
    setOpenKey(null);
  };

  const openSubMenu = (key) => {
    dispatch({ type: APP_LAYOUT_ACTIONS.APP_LAYOUT_SHOW_BACKDROP, payload: { onClick: closeSubMenu } });
    setOpenKey(key);
  };

  const commonProps: CommonNavItemProps = {
    revealed: navBarRevealed,
    openKey,
    openSubMenu,
    closeSubMenu
  };

  const isEnabled = useIsFeatureEnabled();
  const isPermittedTo = useIsPermittedTo(currentProfile);

  const isAccountSwitched = currentProfile.account_id && currentProfile.account_id !== currentAccount.id;

  const isWorkflowPermitted = useIsWorkflowPermitted();
  const isInsightsPermitted = isEnabled(FeatureFlag.InsightsPage) && isPermittedTo(PermissionRule.Insights);
  const isReportsPermitted = isEnabled(FeatureFlag.InsightsPage) && isPermittedTo(PermissionRule.Reporter);
  const isProfilesPermitted = isPermittedTo(PermissionRule.ProfileList);
  const isMarketplacePermitted = useIsMarketplacePermitted();
  const isAuditPlannerPermitted = useIsAuditPlannerPermitted();
  const { isEnabled: isBMEnabled, isPermittedToAccess } = useBookingModulePermissions();
  const isActivityFeedPermitted = isEnabled(FeatureFlag.ActivityFeed);

  return (
    <nav data-qa-id="NavBar" role="navigation">
      <div
        className={classNames(css.root, {
          [css.revealed]: navBarRevealed,
          [css.elevated]: !!openKey
        })}
      >
        <ul className={css.list}>
          <NavLogo />
          {!isAccountSwitched && (
            <>
              <SearchNavItem {...commonProps} />
              <NewActivitiesNavItem {...commonProps} />

              <li className={css.sep} />

              {isMarketplacePermitted && <MarketplaceNavItem {...commonProps} />}
              {isWorkflowPermitted && <WorkflowNavItem {...commonProps} />}
              {isAuditPlannerPermitted && <AuditPlannerNavItem {...commonProps} />}
              {/* {isInsightsPermitted && <InsightsNavItem {...commonProps} />} */}
              {(isInsightsPermitted || isReportsPermitted) && <AnalyticsNavItem {...commonProps} />}
              {isActivityFeedPermitted && <FeedNavItem {...commonProps} />}
              {isProfilesPermitted && <ProfilesNavItem {...commonProps} />}
              {isBMEnabled && isPermittedToAccess && <BookingNavItem {...commonProps} />}
              <li style={{ flexGrow: 2 }} />
              <li className={css.sep} />

              <NotificationsNavItem {...commonProps} />
              <CustomLinksNavItem {...commonProps} />
            </>
          )}
          <AdminNavItem {...commonProps} />
          <YouNavItem {...commonProps} />
        </ul>
      </div>
      <MenuTrigger
        disabled={navBarRevealed}
        onClick={() => {
          dispatch({ type: APP_LAYOUT_ACTIONS.APP_LAYOUT_TOGGLE_NAV });
          dispatch({
            type: APP_LAYOUT_ACTIONS.APP_LAYOUT_SHOW_BACKDROP,
            payload: { onClick: closeSubMenu }
          });
        }}
      />
    </nav>
  );
};
