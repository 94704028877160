import { Camelized, camelizeKeys } from "humps";
import { RequestHelpModal } from "PFApp/activities/show/parts/request_help_modal";
import CloseConfirmModal from "PFApp/components/activity_actions/modals/close_confirm_modal";
import { ExpiryDateWarningModal } from "PFApp/components/activity_actions/modals/expiry_date_warning_modal";
import { ReopenActivityModal } from "PFApp/components/reopen_activity_modal";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import { useGrowl } from "PFApp/use_growl";
import { DropdownButton } from "PFComponents/dropdown_button";
import { Modal } from "PFComponents/modal";
import { useActivityDelete } from "PFCore/hooks/queries/activity/use_activity_delete";
import DotsIcon from "PFIcons/vertical_dots.svg";
import { Activity, Id } from "PFTypes";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import css from "./activity_actions.module.scss";
import { useThreeDotsMenuOptions } from "./use_three_dots_menu_options";

type ExpiryDateWarningModalData = {
  show: boolean;
  mode?: "edit" | "clone";
};

type ActivityActionsProps = {
  item: Activity;
  type: TemplateKey;
  pinnedIds?: number[];
  setPinnedIds?: React.Dispatch<React.SetStateAction<number[]>>;
  onShare?: (activityId: Id) => void;
  onRefetch?: VoidFunction;
};

export const ActivityActions = ({
  item,
  type,
  pinnedIds = [],
  setPinnedIds,
  onShare,
  onRefetch = () => {}
}: ActivityActionsProps) => {
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);
  const [showCloseConfirm, setShowCloseConfirm] = useState(false);
  const [showRequestHelpModal, setShowRequestHelpModal] = useState(false);
  const { t } = useTranslation("workflow", { keyPrefix: "parts.threeDotsMenu" });
  const navigate = useNavigate();

  const { mutateAsync: deleteActivity } = useActivityDelete();

  const template = useTemplate({ key: type });

  const [showExpiryDateModal, setShowExpiryDateModal] = useState(false);
  const [expiryDateWarningModalData, setExpiryDateWarningModalData] = useState<ExpiryDateWarningModalData>({
    show: false
  });

  const growl = useGrowl();

  const handlePinnedChange = useCallback(
    (isPinned) => {
      const newPinned = [...pinnedIds];
      const index = newPinned.indexOf(item.id);
      if (isPinned) {
        index === -1 && newPinned.unshift(item.id);
      } else {
        index !== -1 && newPinned.splice(index, 1);
      }
      setPinnedIds?.(newPinned);
    },
    [item.id, pinnedIds, setPinnedIds]
  );

  const performIfNotExpired = useCallback(
    ({ action, mode }: { action: VoidFunction; mode: ExpiryDateWarningModalData["mode"] }) => {
      const { post_until } = item;
      const isExpired = new Date(post_until) < new Date();
      if (isExpired) {
        setExpiryDateWarningModalData({
          show: true,
          mode
        });
      } else {
        action();
      }
    },
    [item]
  );

  const handleRemove = () => {
    deleteActivity(item.id).then(
      () => {
        growl({ message: t("activityTypeRemove.success", { type }) });
        setPinnedIds && handlePinnedChange(false);
        onRefetch?.();
      },
      (resp) => {
        const msg = resp.status === 404 ? "activityTypeRemove.error404" : "activityTypeRemove.error";
        growl({ message: t(msg, { type }), kind: "error" });
      }
    );
  };

  const { options, onClose } = useThreeDotsMenuOptions({
    item,
    pinnedIds,
    type,
    coowned: item.coowned,
    onEdit: () =>
      performIfNotExpired({
        action: () => navigate(`/activities/${item.id}/edit`, { state: { utmSource: "workflow" } }),
        mode: "edit"
      }),
    onClone: () =>
      performIfNotExpired({
        action: () =>
          template && navigate(`/activities/${template.key}/new/${item.id}`, { state: { clone: true } }),
        mode: "clone"
      }),
    onReopen: () => setShowExpiryDateModal(true),
    onShare: () => (onShare ? onShare(item.id) : undefined),
    onRequestHelp: () => setShowRequestHelpModal(true),
    onPrioritySelected: onRefetch,
    onRemove: () => setShowRemoveConfirm(true),
    onClose: () => setShowCloseConfirm(true),
    onPinned: setPinnedIds ? (isPinned) => handlePinnedChange(isPinned) : undefined
  });

  return (
    <>
      <DropdownButton
        options={options}
        buttonKind="blank"
        dropDownClassName={css.dropdown}
        dropDownStyle={{ maxHeight: 300 }}
        handleClick={(event) => event.preventDefault()}
        handleChange={(optionItem) => (optionItem.func ? optionItem.func() : optionItem())}
        handleClose={onClose}
        qaId={`WorkflowPageRowMoreButton.${item.id}`}
      >
        <DotsIcon width={20} height={17} />
      </DropdownButton>

      {showRemoveConfirm && (
        <Modal
          title={t("activityTypeRemove.title", { type })}
          onOK={handleRemove}
          onClose={() => setShowRemoveConfirm(false)}
          kindOK="danger"
        >
          {t("activityTypeRemove.confirm", { type })}
        </Modal>
      )}
      {showCloseConfirm && (
        <CloseConfirmModal
          activity={item}
          handleClose={() => setShowCloseConfirm(false)}
          type={type}
          handleSuccess={onRefetch}
        />
      )}

      {expiryDateWarningModalData.show && expiryDateWarningModalData.mode && template && (
        <ExpiryDateWarningModal
          activityId={item.id}
          templateKey={template.key}
          mode={expiryDateWarningModalData.mode}
          onClose={() => setExpiryDateWarningModalData({ show: false })}
        />
      )}
      {showExpiryDateModal && (
        <ReopenActivityModal
          activity={camelizeKeys(item) as Camelized<Activity>}
          onClose={() => setShowExpiryDateModal(false)}
        />
      )}

      {showRequestHelpModal && (
        <RequestHelpModal activity={item} onClose={() => setShowRequestHelpModal(false)} />
      )}
    </>
  );
};
