import classNames from "classnames";
import { ActionIcon } from "PFComponents/action_icon";
import { Color } from "PFComponents/icon";
import { AriaAttributes, forwardRef, SyntheticEvent } from "react";

import { Typography } from "../typography";
import css from "./pill.module.scss";

type PillKind = "danger" | "warning" | "success" | "general" | "regular";

const ICON_COLORS: Record<PillKind, Color> = {
  danger: "paletteRed1",
  warning: "paletteOrange1",
  success: "paletteGreen1",
  general: "paletteBlue0",
  regular: "paletteBlue0"
};

type ConditionalProps =
  | {
      text: string;
      children?: never;
    }
  | {
      text?: never;
      children: React.ReactNode;
    };

export type PillProps = {
  small?: boolean;
  kind?: "danger" | "warning" | "success" | "general" | "regular";
  onDelete?: (event: SyntheticEvent) => void;
  deleteTitle?: string;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
} & ConditionalProps &
  AriaAttributes;

export const Pill = forwardRef<HTMLDivElement, PillProps>(
  (
    {
      text,
      small = false,
      onDelete,
      deleteTitle,
      kind = "general",
      children,
      className,
      style,
      title,
      "aria-label": ariaLabel
    },
    ref
  ) => {
    const pillClassNames = classNames(
      css.root,
      css[`pill-${kind}`],
      {
        [css.smallPill]: small,
        [css.withDeleteAction]: onDelete
      },
      className
    );

    const renderPillContent = () => (
      <Typography variant={"bodyRegular"} tag="span" className={classNames({ [css.smallText]: small })}>
        {children ?? text}
      </Typography>
    );

    return (
      <div ref={ref} className={pillClassNames} style={style}>
        {onDelete && (
          <ActionIcon
            name="cross"
            size={small ? "xs" : "sm"}
            onClick={onDelete}
            title={deleteTitle}
            aria-label={deleteTitle}
            color={ICON_COLORS[kind]}
          />
        )}
        <div title={title} aria-label={ariaLabel ?? text}>
          {renderPillContent()}
        </div>
      </div>
    );
  }
);

Pill.displayName = "Pill";
