import snakeCase from "lodash/snakeCase";
import { ActionIcon } from "PFComponents/action_icon";
import { Icon } from "PFComponents/icon";
import { useMemo } from "react";

import { NavDropdownItem as NavDropdownItemType } from "../../../types";
import { NavLink } from "../../nav_link";
import { NavItemProps } from "../nav_item";
import css from "./nav_dropdown.module.scss";

export type NavDropdownItemProps = {
  item: NavDropdownItemType;
  closeSubMenu: NavItemProps["closeSubMenu"];
};

const getQaId = (name) => `NavDropdownItem.${snakeCase(name)}`;

export const NavDropdownItem = ({ item, closeSubMenu }: NavDropdownItemProps) => {
  const { action, badge, displayItem, external, href, name, rel, target, qaIdName } = item;
  const id = item.id !== undefined ? String(item.id) : undefined;
  const title = item.title || name || "";

  const handleActionClick = () => {
    closeSubMenu();
    action?.callback?.();
  };

  const itemContent = useMemo(
    () => (
      <div className={css.itemContent} aria-label={title}>
        <span className={css.overflowed} title={title}>
          {displayItem || name}
        </span>
        {badge && (
          <div className={css.badgeItem}>
            <span>{badge}</span>
          </div>
        )}
      </div>
    ),
    [badge, displayItem, name, title]
  );

  return (
    <li data-qa-id={getQaId(`${qaIdName || name}`)}>
      {href &&
        (external ? (
          <a href={href} target={target} className={css.link} id={id} rel={rel}>
            {itemContent}
          </a>
        ) : (
          <NavLink to={href} target={target} className={css.link} id={id} onTransitioning={closeSubMenu}>
            {itemContent}
          </NavLink>
        ))}
      {action &&
        (action.href ? (
          <NavLink
            aria-label={action.label}
            className={css.action}
            to={action.href}
            data-qa-id={`NavDropdownItem.action`}
            onTransitioning={closeSubMenu}
          >
            <Icon name={action.icon || "add"} size="sm" />
          </NavLink>
        ) : (
          <span className={css.action}>
            <ActionIcon
              title={action.label}
              qaId={`NavDropdownItem.action`}
              name={action.icon || "add"}
              onClick={handleActionClick}
              size="sm"
            />
          </span>
        ))}
    </li>
  );
};
