import { TableColumn } from "PFApp/components/table/table_types";

const COLUMNS_SIZE = {
  1: 100,
  2: 180,
  3: 250
};

export const getColumnConfig = (column: TableColumn) => ({
  id: column.name,
  header: column.title,
  enableSorting: !!column.orderKey,
  size: COLUMNS_SIZE[column.size || 2]
});
