import { Flex, Inline } from "PFComponents/containers";
import { Icon } from "PFComponents/icon";
import Tooltip from "PFComponents/tooltip/tooltip";
import { Activity } from "PFTypes";
import { Link } from "react-router-dom";
import { useLocation } from "react-use";

import NotificationItem from "../../../../components/table/notification_item";
import { Notification } from "../../../../components/table/table_types";

type ActivityNameCellProps = {
  item: Activity & { notifications?: Notification[] };
};

export const ActivityNameCell = ({ item }: ActivityNameCellProps) => {
  const { search } = useLocation();

  const hasNotifications = (item.notifications || []).length > 0;

  const tooltipContent = hasNotifications && (
    <Flex direction="column" gap="spacingLg">
      {item.notifications?.map((notification) => (
        <NotificationItem key={notification.id} notification={notification} />
      ))}
    </Flex>
  );

  return (
    <Inline alignItems="center">
      <Link
        to={{
          pathname: `/activities/${item.id}`,
          search
        }}
        state={{ utmSource: "workflow" }}
        data-qa-id={`WorkflowPageRowActivityLink.${item.id}`}
      >
        {item.name}
      </Link>
      {tooltipContent && (
        <Tooltip
          content={tooltipContent}
          placement="bottom-start"
          arrow={false}
          visible={true}
          popperOptions={{
            modifiers: [
              {
                name: "flip",
                enabled: false
              },
              {
                name: "preventOverflow",
                options: {
                  altBoundary: true
                }
              }
            ]
          }}
        >
          <Icon name="info" />
        </Tooltip>
      )}
    </Inline>
  );
};
