import { ColumnHelper } from "@tanstack/react-table";
import compact from "lodash/compact";
import map from "lodash/map";
import { TableColumn } from "PFApp/components/table/table_types";
import { TemplateKey } from "PFApp/constants/templates";
import { useTableSorting } from "PFApp/hooks/use_table_sorting";
import Table from "PFComponents/table";
import { PaginationOptions } from "PFComponents/table/table.types";
import { Activity, Id, MetaWithPagination } from "PFTypes";
import { useCallback } from "react";

import NoItems from "../../../components/table/no_items";
import css from "./activities_table.module.scss";
import { RowActionsCell } from "./cells";
import { getColumnConfig } from "./get_column_config";
import { useCellFactory } from "./use_cell_factory";

type ActivitiesTableProps = {
  type: TemplateKey.Role | TemplateKey.Engagement;
  activities: Activity[];
  columns: TableColumn[];
  meta?: MetaWithPagination;
  isLoading?: boolean;
  selectedIds?: Record<string, true>;
  pinnedIds?: number[];
  setPinnedIds?: React.Dispatch<React.SetStateAction<number[]>>;
  setSelectedIds?: React.Dispatch<React.SetStateAction<Record<string, true>>>;
  onMarkAsRead?: (activityId: Id) => Promise<void>;
  onPageChange?: (page: number) => void;
  onShareActivity?: (activityId: Id) => void;
  onRefetch?: VoidFunction;
  onOrderChange?: any;
};

export const ActivitiesTable = ({
  type,
  activities,
  columns,
  meta,
  isLoading,
  selectedIds,
  pinnedIds,
  setPinnedIds,
  setSelectedIds,
  onMarkAsRead,
  onShareActivity,
  onPageChange,
  onRefetch,
  onOrderChange
}: ActivitiesTableProps) => {
  const renderCell = useCellFactory(type);

  const paginationOptions: PaginationOptions | undefined = meta && {
    currentPage: meta.page,
    totalPages: meta.totalPages,
    onClick: onPageChange,
    className: css.stickyPagination
  };

  const { sorting, handleSortingChange } = useTableSorting({
    metaOrder: meta?.order,
    setParamsOrder: onOrderChange,
    parseMetaOrderName: (name) => name.split(".").pop() || name,
    parseTableSortingId: (id) => `activity.${id}`
  });

  const generateColumns = useCallback(
    (columnHelper: ColumnHelper<Activity>) =>
      compact([
        ...map(columns, (column) =>
          columnHelper.accessor((row) => row[column.name], {
            ...getColumnConfig(column),
            cell: renderCell(column)
          })
        ),
        columnHelper.accessor((row) => row, {
          id: "actions",
          header: "",
          enableSorting: false,
          size: 64,
          cell: ({ getValue }) => (
            <RowActionsCell
              item={getValue()}
              type={type}
              pinnedIds={pinnedIds}
              onMarkAsRead={onMarkAsRead}
              setPinnedIds={setPinnedIds}
              onShare={onShareActivity}
              onRefetch={onRefetch}
            />
          )
        })
      ]),
    [columns, pinnedIds]
  );

  if (activities.length === 0) {
    return <NoItems itemType={type === TemplateKey.Role ? "role" : "engagement"} />;
  }

  return (
    <Table<Activity>
      className={css.activitiesTable}
      isLoading={isLoading}
      tableData={activities}
      generateColumns={generateColumns}
      paginationOptions={paginationOptions}
      disableAutoResizing
      disableInternalScrolling
      enableRowSelection={!!setSelectedIds}
      enableMultiRowSelection
      onRowSelectionChange={setSelectedIds}
      rowSelection={selectedIds}
      getRowId={({ id }) => String(id)}
      onSortingChange={handleSortingChange}
      controlledSorting={sorting}
      enableSorting={!!meta?.order}
    />
  );
};
