import PriorityDropdown from "PFApp/components/table/priority_dropdown";
import { TemplateKey } from "PFApp/constants/templates";
import { useTemplate } from "PFApp/hooks";
import useIsRoleRemovalPermitted from "PFApp/use_is_role_removal_permitted";
import { DropdownOption } from "PFComponents/dropdown";
import { Typography } from "PFComponents/typography";
import { isOpen } from "PFCore/helpers/activities";
import { activityCustomTypesAccessLevels } from "PFCore/helpers/custom_types";
import { getVisibleProperties, isSubtemplate } from "PFCore/helpers/templates";
import { useCustomTypes } from "PFCore/helpers/use_custom_types";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import RightArrowIcon from "PFIcons/right_arrow.svg";
import { Activity } from "PFTypes";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper } from "react-popper";

import css from "./activity_actions.module.scss";

type UseOptions = {
  item: Activity;
  pinnedIds: number[];
  type: TemplateKey;
  coowned: boolean;
  onEdit: VoidFunction;
  onClone: VoidFunction;
  onReopen: VoidFunction;
  onShare?: VoidFunction;
  onPrioritySelected: VoidFunction;
  onRequestHelp?: (id: number) => void;
  onRemove: VoidFunction;
  onClose: VoidFunction;
  onPinned?: (isPinned: boolean) => void;
};

type UseOptionsReturn = {
  options: DropdownOption[];
  onClose: VoidFunction;
};

export const useThreeDotsMenuOptions = ({
  item,
  pinnedIds,
  type,
  coowned,
  onEdit,
  onClone,
  onReopen,
  onShare,
  onRequestHelp,
  onPrioritySelected,
  onRemove,
  onClose,
  onPinned
}: UseOptions): UseOptionsReturn => {
  const { t } = useTranslation("workflow", { keyPrefix: "parts.threeDotsMenu" });
  const { t: translation } = useTranslation("translation");
  const { data: currentProfile } = useCurrentProfile();
  const { customTypes } = useCustomTypes();
  const removePermitted = useIsRoleRemovalPermitted();

  const [showPriorityDropdown, setShowPriorityDropdown] = useState(false);
  const popperReferenceElement = useRef(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { styles, attributes } = usePopper(popperReferenceElement.current, popperElement, {
    placement: "left-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        enabled: true,
        options: {
          offset: [0, 20]
        }
      }
    ]
  });

  const isAudit = type.includes("audit");
  const isRole = type.includes("role");
  const template = useTemplate({ key: type });
  const isSubtemplateMissing =
    isRole && !!item.subtemplate_key && !!template && !isSubtemplate(template, item.subtemplate_key);
  const isPinned = pinnedIds.includes(item.id);

  const isPriorityCustomTypeAvailable = useMemo(() => {
    const priorityProperty = getVisibleProperties(template).find(({ name }) => name === "priority");
    const priorityCustomType = customTypes.find((type) => type.name === "priority");
    return (
      priorityProperty &&
      priorityCustomType &&
      activityCustomTypesAccessLevels(currentProfile)[priorityCustomType.id]
    );
  }, [customTypes, currentProfile, template]);

  const items: (DropdownOption & { visible: boolean })[] = [
    {
      id: "pinned",
      visible: !!onPinned,
      displayElement: isPinned ? t("unmarkImportant") : t("markAsImportant"),
      item: () => onPinned?.(!isPinned)
    },
    {
      id: "edit",
      visible: !isAudit && coowned && !isSubtemplateMissing,
      displayElement: translation("edit"),
      item: onEdit
    },
    {
      id: "reopen",
      visible: isAudit && isRole && coowned && !isOpen(item),
      displayElement: translation("reopen"),
      item: onReopen
    },
    {
      id: "share",
      visible: !!onShare,
      displayElement: t("share"),
      item: onShare
    },
    {
      id: "clone",
      visible: !!template && !template.hidden && template.create_permitted && !isSubtemplateMissing,
      displayElement: t("clone"),
      item: onClone
    },
    {
      id: "request_help",
      visible: coowned,
      displayElement: t("requestHelp"),
      item: onRequestHelp
    },
    {
      id: "priority",
      visible: coowned && isPriorityCustomTypeAvailable,
      displayElement: (
        <div>
          <div ref={popperReferenceElement} className={css.priorityButton}>
            <Typography variant="bodyRegular" clipOverflow>
              {t("priority.option")}
            </Typography>
            <RightArrowIcon width={23} height={20} />
          </div>
          {showPriorityDropdown && (
            <div
              ref={setPopperElement}
              style={{ ...styles.popper, zIndex: 20, marginBottom: -25 }} // workaround for positioning the popper vertically
              {...attributes.popper}
            >
              <PriorityDropdown activity={item} onSelected={onPrioritySelected} />
            </div>
          )}
        </div>
      ),
      item: {
        keepOpen: true,
        func: () => setShowPriorityDropdown((prev) => !prev)
      }
    },
    {
      id: "remove",
      visible: !isAudit && coowned && removePermitted,
      displayElement: (
        <Typography variant="bodyRegular" className={css.dangerButton} clipOverflow>
          {translation("remove")}
        </Typography>
      ),
      item: onRemove
    },
    {
      id: "close",
      visible: coowned && isOpen(item),
      displayElement: (
        <Typography variant="bodyRegular" className={css.dangerButton} clipOverflow>
          {translation("close")}
        </Typography>
      ),
      item: onClose
    }
  ];

  return {
    options: items.filter(({ visible }) => visible),
    onClose: () => setShowPriorityDropdown(false)
  };
};
