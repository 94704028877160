import { SortingState } from "@tanstack/react-table";
import identity from "lodash/identity";
import isEqual from "lodash/isEqual";
import last from "lodash/last";
import { OrderMeta, OrderOption } from "PFTypes";
import { useCallback, useMemo } from "react";

type UseTableSorting = {
  metaOrder: OrderMeta | undefined;
  setParamsOrder: (newOrder: OrderOption | undefined) => void;
  parseMetaOrderName?: (name: string) => string;
  parseTableSortingId?: (id: string) => string;
};

const invertDirection = (direction: string) => (direction === "asc" ? "desc" : "asc");

export const useTableSorting = ({
  metaOrder,
  setParamsOrder,
  parseMetaOrderName = identity,
  parseTableSortingId = identity
}: UseTableSorting) => {
  const rawValue = metaOrder?.value;
  const defaultOrder = metaOrder?.defaultValue;

  const sorting = useMemo(() => {
    if (!rawValue) {
      return [];
    }

    return [
      {
        id: parseMetaOrderName(last(rawValue.name) as string),
        desc: rawValue.direction === "desc"
      }
    ];
  }, [rawValue, parseMetaOrderName]);

  const handleSortingChange = useCallback(
    (newSorting: SortingState) => {
      const isNewOrderNone = newSorting.length === 0;
      const isCurrentOrderDefault = !!defaultOrder && !!rawValue && isEqual(rawValue, defaultOrder);

      if (isNewOrderNone && isCurrentOrderDefault && !!rawValue) {
        // When order changes from default order to [], the ordering doesn't change,
        // because API will provide default order for undefined order
        // To avoid unchanged order onSortingChange, [] order is skipped and current one is inverted
        const sameOptionInvertedDirection = metaOrder?.options.find(
          (option) =>
            isEqual(option.name, rawValue.name) && option.direction === invertDirection(rawValue.direction)
        );

        setParamsOrder(sameOptionInvertedDirection);
        return;
      }

      if (isNewOrderNone) {
        setParamsOrder(undefined);
        return;
      }

      const { id, desc } = newSorting[0];

      const direction = desc ? "desc" : "asc";
      const option = metaOrder?.options.find(
        (option) =>
          option.name.some((name) => name === parseTableSortingId(id)) && option.direction === direction
      );

      setParamsOrder(option);
    },
    [defaultOrder, rawValue, metaOrder?.options, setParamsOrder, parseTableSortingId]
  );

  return {
    sorting,
    handleSortingChange
  };
};
