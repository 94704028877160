import { useAppContext } from "PFApp/app_context";
import { ActivityPreview } from "PFApp/components/activity_preview/activity_preview";
import Chats from "PFApp/components/chats/chats";
import { Growls } from "PFApp/components/growls";
import LeftActionPanel from "PFApp/components/left_action_panel/left_action_panel";
import { Navbar } from "PFApp/components/navbar/navbar";
import { ProfilePreview } from "PFApp/components/profile_preview";
import { TermsOfServiceModal } from "PFApp/layout/footer/parts/terms_of_service_modal";
import useIsChatEnabled from "PFApp/use_is_chat_enabled";
import { useLiveSessionCheck } from "PFApp/use_live_session_check";
import { ActionDiv } from "PFComponents/containers";
import { admin, auto, headerOnly, sidebar } from "PFCore/base/page_layouts";
import { isMsTeams } from "PFCore/helpers/ms_teams";
import useDebounce from "PFCore/helpers/use_debounce";
import useWindowSize from "PFCore/helpers/use_window_size";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { ONE_MINUTE_MS } from "PFCore/utilities/time_consts";
import { APP_LAYOUT_ACTIONS } from "PFReducers/app_layout_reducer";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { CustomValuePreview } from "./components/custom_value_preview";
import { SkipLinksRegion, SkipToContent } from "./components/skip_to_content";
import css from "./react_app_layout.less";

const ReactAppLayout = ({ children }) => {
  const { t } = useTranslation("translations");
  const {
    store: {
      appLayout: {
        leftActionPanel,
        rightActionPanel,
        navBarRevealed,
        mainLeft,
        mainRight,
        backdropOnClicks,
        edgeToEdge,
        layout
      },
      termsShown
    },
    dispatch
  } = useAppContext();
  const { isSignedIn } = useCurrentProfile();
  const {
    data: { live_ttl_in_minutes }
  } = useCurrentAccount();

  useLiveSessionCheck(((isSignedIn && live_ttl_in_minutes) || 0) * ONE_MINUTE_MS);

  const displaySidebar = isSignedIn && (layout === sidebar || layout === admin || layout === auto);

  const isChatEnabled = useIsChatEnabled();
  const displayChat = isChatEnabled && isSignedIn && layout === sidebar && !isMsTeams();

  const { windowWidth, windowHeight } = useWindowSize();

  const mainStyle = {
    marginLeft: mainLeft,
    marginRight: mainRight
  };

  const recalculate = () => {
    dispatch({
      type: APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_MAIN_DIMENSIONS,
      payload: _calculateMainDimensions({
        layout,
        windowWidth,
        windowHeight,
        leftActionPanel,
        rightActionPanel,
        navBarRevealed,
        edgeToEdge
      })
    });
  };

  useEffect(useDebounce(recalculate, 100), [
    layout,
    leftActionPanel.collapsed,
    rightActionPanel.collapsed,
    navBarRevealed,
    windowWidth
  ]);

  useEffect(() => {
    recalculate();
  }, [edgeToEdge]);

  return (
    <>
      <SkipLinksRegion>
        <SkipToContent targetId="main-content" text={t("skipToMainContent")} />
      </SkipLinksRegion>
      <div id="modal_region">{/* Portal for React components */}</div>
      {displaySidebar && (
        <>
          <Navbar />
          <LeftActionPanel />
        </>
      )}
      {children && (
        <main className="main_region" style={mainStyle}>
          {children}
        </main>
      )}
      {displayChat && <Chats />}
      <Growls />
      {displaySidebar && (
        <>
          <ProfilePreview />
          <ActivityPreview />
          <CustomValuePreview />
        </>
      )}
      {termsShown && <TermsOfServiceModal />}
      {backdropOnClicks.length && (
        <ActionDiv
          data-qa-id="ReactAppLayout.backdrop"
          className={css.backdrop}
          onClick={() => dispatch({ type: APP_LAYOUT_ACTIONS.APP_LAYOUT_HIDE_BACKDROP })}
        />
      )}
    </>
  );
};

ReactAppLayout.propTypes = {
  children: PropTypes.node
};

const _calculateMainDimensions = ({ layout, windowWidth, leftActionPanel, rightActionPanel, edgeToEdge }) => {
  if (layout === headerOnly) {
    return { mainLeft: 0, mainRight: 0, mainWidth: windowWidth, windowWidth };
  }

  const navShown = windowWidth > 500; // small screen => nav with a backdrop => doesn't affect the viewport
  const bothPanelsOpen = !leftActionPanel.collapsed && !rightActionPanel.collapsed;

  // feed seams to be the widest for now
  // but when we move feed panel to the leftActionPanel 1300 can be lowered!
  const isSmallScreen = windowWidth <= (bothPanelsOpen ? 1600 : 1300);

  let mainLeft = (navShown ? 60 : 0) + (leftActionPanel.collapsed || isSmallScreen ? 0 : 400);
  let mainRight = 0;

  if (!edgeToEdge) {
    if (windowWidth > 900) {
      mainLeft += 40;
      mainRight += 40;
    } else if (windowWidth > 500) {
      mainLeft += 30;
      mainRight += 30;
    } else {
      mainLeft += 20;
      mainRight += 20;
    }
  }

  return {
    mainLeft,
    mainRight,
    mainWidth: windowWidth - mainLeft - mainRight,
    windowWidth
  };
};

export const useEdgeToEdgeLayout = () => {
  const { dispatch } = useAppContext();
  const type = APP_LAYOUT_ACTIONS.APP_LAYOUT_SET_EDGE_TO_EDGE;

  useEffect(() => {
    dispatch({ type, payload: true });
    return () => dispatch({ type, payload: false });
  }, []);
};

export default ReactAppLayout;
