import map from "lodash/map";
import PriorityBadge from "PFApp/components/table/priority_badge";
import ProfileCell from "PFApp/components/table/profile_cell";
import { TableColumn } from "PFApp/components/table/table_types";
import { TemplateKey } from "PFApp/constants/templates";
import SimpleBadge from "PFComponents/badges_list/simple_badge";
import YesNo from "PFComponents/icons/yes_no";
import { EngagementRolesBadge } from "PFComponents/role/engagement_roles_badge";
import { RoleStatus } from "PFComponents/role/role_status_badge";
import { daysFromNowFormat } from "PFCore/helpers/date";
import { useDateFormatter } from "PFCore/hooks";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

import { ActivityNameCell } from "./cells";
import { getRolesCellRenderers } from "./roles_cell_renderers";

export const useCellFactory = (type: TemplateKey.Role | TemplateKey.Engagement) => {
  const { t } = useTranslation(["core", "workflow"]);

  const { search } = useLocation();
  const { formatDate, formatDateTime } = useDateFormatter();

  const cellRenderers = {
    // Activity Rows
    "name": ({ row }) => <ActivityNameCell item={row.original} />,
    "id": ({ getValue }) => {
      const id = getValue();
      return (
        <Link
          to={{
            pathname: `/activities/${id}`,
            search
          }}
          state={{ utmSource: "workflow" }}
        >
          {id}
        </Link>
      );
    },
    "profile": ({ getValue }) => {
      const item = getValue();
      return item && <ProfileCell profile={item} />;
    },
    "custom_field": ({ getValue }) => {
      const item = getValue();
      return Array.isArray(item) ? map(item, "text").join(", ") : item;
    },
    "noOfDays": ({ row }) => {
      const duration = row.original.number_of_days;
      return duration && t("workflow:parts.day", { count: row.original.number_of_days });
    },
    "date": ({ getValue }) => getValue() && formatDate(getValue()),
    "date-time": ({ getValue }) => getValue() && formatDateTime(getValue()),
    "toggle": ({ getValue }) => <YesNo yes={getValue()} />,
    "post_until": ({ getValue }) => formatDate(getValue()),
    "priority": ({ getValue }) => {
      const priority = getValue()?.[0];
      return priority && <PriorityBadge level={priority.text.toLowerCase()} />;
    },
    "status": ({ row }) => {
      const status: RoleStatus = row.original.state;
      return <SimpleBadge small text={t(`core:roleStatus.state.${status}`)} />;
    },
    "assignee": ({ getValue }) => {
      const item = getValue();
      return item && <ProfileCell profile={item.profile} qaId={`WorkflowPageRowProfileLink.${item.id}`} />;
    },
    "due": ({ row }) => {
      const item = row.original;
      return item.state === "complete" ? "-" : daysFromNowFormat(item.post_until);
    },
    // Engagement Rows
    ...(type === TemplateKey.Engagement && {
      roles: ({ row }) => <EngagementRolesBadge engagement={row.original} />
    }),
    // Roles Rows
    ...(type === TemplateKey.Role && getRolesCellRenderers())
  };

  const defaultRenderer = ({ getValue }) => getValue() && String(getValue());

  return (column: TableColumn) =>
    cellRenderers[column.name] || cellRenderers[column.type!] || defaultRenderer;
};
