import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const NoItems = ({ itemType }) => {
  const { t } = useTranslation("workflow");

  return (
    <div style={{ padding: 30, textAlign: "center" }}>
      {itemType === "role"
        ? t("parts.noItems.roles")
        : itemType === "engagement"
        ? t("parts.noItems.engagements")
        : t("parts.noItems.other")}
    </div>
  );
};

NoItems.propTypes = {
  itemType: PropTypes.string
};

export default NoItems;
