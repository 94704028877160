export enum FeatureFlag {
  ActivityAiGenerator = "ai_generated_description",
  ActivityCanCreateValues = "activity_can_create_values",
  ActivityFeed = "activity_feed",
  ActivityPhases = "activity_phases",
  AuditPlanner = "audit_planner",
  AuditAllocationWindows = "audit_allocation_windows",
  AutoSaml = "auto_saml",
  BookingModule = "booking_module",
  Chameleon = "chameleon",
  ChargeableFilter = "chargeable_filter",
  Chat = "chat",
  DigestEmail = "digest_email",
  EngagementEconomics = "engagement_economics",
  EngagementFeedback = "engagement_feedback",
  Facebook = "facebook",
  GoogleAnalytics = "google_analytics",
  InsightsPage = "insights_page",
  Instagram = "instagram",
  InterestsSummary = "interests_summary",
  LearningAndDevelopment = "learning_and_development",
  LinkedinSignin = "linkedin_signin",
  NativeNotifications = "native_notifications",
  PasswordSignin = "password_signin",
  ProfileExporter = "profile_exporter",
  ReportsTo = "reports_to",
  RequestAccess = "request_access",
  RequireAssignee = "require_assignee",
  SamlSignin = "saml_signin",
  SecureDomains = "secure_domains",
  SessionStorage = "session_storage",
  ShowActivityId = "show_activity_id",
  ShowUtilization = "show_utilization",
  SignInAdminAsSomeone = "sign_in_admin_as_someone",
  SkillsFramework = "skills_framework",
  Skype = "skype",
  SkypeBusiness = "skype_business",
  SoldFilter = "sold_filter",
  SuspendedProfiles = "suspended_profiles",
  TalentMarketplace = "talent_marketplace",
  Twitter = "twitter",
  UnbiasedAllocation = "unbiased_allocation",
  Website = "website",
  ReportFieldsGrouping = "reports_group_by"
}
