import { useEffect } from "react";
import { Link, LinkProps, useViewTransitionState } from "react-router-dom";

type NavLinkProps = LinkProps & {
  onTransitioning?: VoidFunction;
};

// Adding onTransitioning to Link to fix issues with RouterPrompt:
// useBlocker created multiple times when onClick action triggers rerender
// onTransitions works similar to onClick but triggers callback on actual navigation
// Issues related:
// https://github.com/remix-run/react-router/issues/12182
// https://github.com/remix-run/react-router/issues/11430
export const NavLink = ({ to, onTransitioning, children, ...props }: NavLinkProps) => {
  const isTransitioning = useViewTransitionState(to);

  useEffect(() => {
    if (isTransitioning) {
      onTransitioning?.();
    }
  }, [isTransitioning]);

  return (
    <Link to={to} viewTransition {...props}>
      {children}
    </Link>
  );
};
