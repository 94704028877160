import { getBadgeColour } from "PFApp/notifications/notification_item";
import { Flex, Stack } from "PFComponents/containers";
import { Typography } from "PFComponents/typography";
import SimpleBadge from "PFCore/components/badges_list/simple_badge";
import { agoFormat } from "PFCore/helpers/date";
import { useMemo } from "react";

import { Notification } from "./table_types";

type NotificationItemProps = {
  notification: Notification;
};

const NotificationItem = ({
  notification: { title, created_at, category_type, body }
}: NotificationItemProps) => {
  const badgeColour = useMemo(() => getBadgeColour(category_type), [category_type]);

  return (
    <Stack gap="spacingMd">
      <Flex justifyContent="space-between" alignItems="start">
        <SimpleBadge text={title} kind={badgeColour} />
        <Typography variant="labelRegular">{agoFormat(created_at)}</Typography>
      </Flex>
      <div>{body}</div>
    </Stack>
  );
};

export default NotificationItem;
