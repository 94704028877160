import clamp from "lodash/clamp";
import isNaN from "lodash/isNaN";
import isNumber from "lodash/isNumber";
import { useSynchronizedState } from "PFCore/hooks/use_synchronized_state";
import { KeyboardEvent, RefObject } from "react";

import { useSliderContext } from "../../context/slider_context";
import { MultiRangeValue } from "../../multirange_slider";
import { SideToolsProps } from "./side_tools";

type ValueKey = keyof MultiRangeValue;

type UseSideTools<Value> = {
  minInputRef: RefObject<HTMLInputElement | null>;
  maxInputRef: RefObject<HTMLInputElement | null>;
  localSliderValue: Value;
} & Pick<SideToolsProps<Value>, "onChange">;

const roundToNearestStep = (value: number, min: number, step: number) =>
  Math.round((value - min) / step) * step + min;

export const useSideTools = <Value extends number | MultiRangeValue>({
  minInputRef,
  maxInputRef,
  localSliderValue,
  onChange
}: UseSideTools<Value>) => {
  const { min, max, step, multiRange } = useSliderContext();
  const [localInputsValue, setLocalInputsValue] = useSynchronizedState<Value>(localSliderValue);

  const rangeMinValue = (localInputsValue as MultiRangeValue).min;
  const rangeMaxValue = (localInputsValue as MultiRangeValue).max;

  const handleInputChange = (newLocalValue: string, key: ValueKey) => {
    if (!newLocalValue) {
      return;
    }

    if (key === "min" && document.activeElement !== minInputRef.current) {
      minInputRef.current?.focus();
    } else if (key === "max" && document.activeElement !== maxInputRef.current) {
      maxInputRef.current?.focus();
    }

    const numberNewValue = Number(newLocalValue);
    setLocalInputsValue(
      (currentLocalValue) =>
        (multiRange
          ? { ...(currentLocalValue as MultiRangeValue), [key]: numberNewValue }
          : numberNewValue) as Value
    );
  };

  const handleValueApply = (newValue: number, key: ValueKey) => {
    const currentValue: number = multiRange ? localSliderValue[key] : localSliderValue;
    const isNewValueEmpty = !isNumber(newValue) || isNaN(newValue);
    const newValueToApply = !isNewValueEmpty ? newValue : currentValue;
    const roundedValue = roundToNearestStep(newValueToApply, min, step);

    let clampedValue = clamp(roundedValue, min, max);
    if (multiRange) {
      if (key === "min") {
        clampedValue = clamp(clampedValue, min, rangeMaxValue - 1);
      } else if (key === "max") {
        clampedValue = clamp(clampedValue, rangeMinValue + 1, max);
      }
    }
    const formattedNewValue = (
      multiRange ? { ...(localInputsValue as MultiRangeValue), [key]: clampedValue } : clampedValue
    ) as Value;

    setLocalInputsValue(formattedNewValue);
    onChange(formattedNewValue);

    if (isNewValueEmpty) {
      // When input value is cleared, the state will not change, so the component will not rerender
      if (key === "min") {
        minInputRef.current!.value = String(clampedValue);
      } else {
        maxInputRef.current!.value = String(clampedValue);
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, key: ValueKey) => {
    if (event.key === "Enter") {
      handleValueApply((event.target as HTMLInputElement).valueAsNumber, key);
    }
  };

  return {
    rangeMinValue,
    rangeMaxValue,
    localInputsValue,
    handleInputChange,
    handleValueApply,
    handleKeyDown
  };
};
