import uniqueId from "lodash/uniqueId";
import { Tip } from "PFComponents/text/input_field_set/components/tip";
import { FocusEvent, useCallback, useEffect, useRef, useState } from "react";

import { useActionIcons } from "./action_icons";
import { Dropdown, useDropdown } from "./dropdown";
import { Header } from "./header";
import { Popover } from "./popover";
import css from "./select_v2.module.scss";
import { OptionOriginal, SearchableVariant, SelectV2Props } from "./select_v2.types";
import { getHighlightedOptionId } from "./select_v2.utils";
import { ValueBox } from "./value_box";
import { getKeyDownHandler } from "./value_box/get_key_down_handler";
import { ValueContainer } from "./value_box/value_container";

export const SelectV2 = <T extends OptionOriginal = OptionOriginal>({
  tip,
  label,
  labelTooltip,
  value,
  onRestore,
  onClear,
  onChange,
  onCreateOption,
  createOptionBlocklist,
  onDropdownClose,
  options,
  fetchOptions,
  parseOptions,
  multiple,
  searchableVariant = SearchableVariant.Dynamic,
  renderDisplayValue,
  cacheTime = 300000,
  disabled,
  locked,
  lockedTip,
  required,
  displayValueBelow,
  dropdownId: externalDropdownId,
  isFetchingSelectedOptions: isFetchingSelectedOptionsProp,
  restoreLabel,
  error
}: SelectV2Props<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const valueBoxRef = useRef<HTMLDivElement>(null);
  const localDropdownIdRef = useRef<string>(uniqueId("select-dropdown"));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const actionIcons = useActionIcons({ onRestore, onClear, label, locked, disabled, restoreLabel });

  const dropdownId = externalDropdownId ?? localDropdownIdRef.current;

  const handleOpen = useCallback(
    (value: boolean) => {
      setIsOpen(value);
      value === false && onDropdownClose?.();
    },
    [onDropdownClose]
  );

  const dropdownData = useDropdown({
    dropdownId,
    isOpen,
    setIsOpen: handleOpen,
    value,
    triggerRef: valueBoxRef,
    options,
    fetchOptions,
    parseOptions,
    multiple,
    onChange,
    onCreateOption,
    createOptionBlocklist,
    searchableVariant,
    cacheTime
  });

  useEffect(() => {
    if (isOpen && disabled) {
      handleOpen(false);
    }
  }, [disabled, handleOpen, isOpen]);

  const { highlightedIndex, resultOptions, selectedOptions, isFetchingSelectedOptions, handleKeyDown } =
    dropdownData;

  const isFetchingSelectedOptionsFinal = isFetchingSelectedOptionsProp ?? isFetchingSelectedOptions;

  const handleBlur = (event: FocusEvent<HTMLDivElement>) => {
    const { currentTarget, relatedTarget } = event;
    if (
      relatedTarget &&
      (currentTarget.contains(relatedTarget) || (ref.current && ref.current.contains(relatedTarget)))
    ) {
      if (
        relatedTarget.getAttribute("role") === "option" ||
        relatedTarget.getAttribute("type") === "checkbox"
      ) {
        currentTarget.focus({ preventScroll: true });
      }
      return;
    }
    handleOpen(false);
  };

  const { onKeyDown: handleDropdownOpenStateOnKeyDown } = getKeyDownHandler({ setIsOpen: handleOpen });
  const onValueBoxKeyDown = isOpen ? handleKeyDown : handleDropdownOpenStateOnKeyDown;

  const handleValueBoxClick = () => {
    valueBoxRef.current?.focus();
    handleOpen(!isOpen);
  };

  const hasTip = !!(tip || error);
  return (
    <div className={css.root}>
      <div ref={ref} className={css.container}>
        <Header label={label} labelTooltip={labelTooltip} actionIcons={actionIcons} required={required} />
        <ValueBox<T>
          ref={valueBoxRef}
          selectedOptions={selectedOptions}
          isFetching={isFetchingSelectedOptionsFinal}
          label={label}
          multiple={multiple}
          renderDisplayValue={renderDisplayValue}
          isOpen={isOpen}
          onKeyDown={onValueBoxKeyDown}
          onClick={handleValueBoxClick}
          onChange={onChange}
          onBlur={handleBlur}
          actionIcons={!label ? actionIcons : undefined}
          dropdownId={dropdownId}
          highlightedId={getHighlightedOptionId(highlightedIndex, resultOptions, dropdownId)}
          locked={locked}
          lockedTip={lockedTip}
          disabled={disabled}
          displayValueBelow={displayValueBelow}
          required={required}
          isError={!!error}
        />

        {hasTip && <Tip tip={tip} error={error} />}
        {isOpen && !disabled && !locked && (
          <Popover reference={ref} onClickOutside={() => handleOpen(false)}>
            <Dropdown<T>
              style={{ width: ref.current?.offsetWidth }}
              dropdownId={dropdownId}
              value={value}
              multiple={multiple}
              onBlur={handleBlur}
              dropdownData={dropdownData}
            />
          </Popover>
        )}
      </div>
      {displayValueBelow && selectedOptions.length > 0 && (
        <ValueContainer
          onChange={onChange}
          renderDisplayValue={renderDisplayValue}
          multiple={multiple}
          selectedOptions={selectedOptions}
          className={css.valueContainer}
          isFetching={isFetchingSelectedOptionsFinal}
          locked={locked}
          disabled={disabled}
        />
      )}
    </div>
  );
};
