import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import spacing from "PFTheme/tokens/spacing";
import React, { type Ref, ElementType, forwardRef, HTMLAttributes } from "react";

type Asable =
  | "article"
  | "aside"
  | "div"
  | "dl"
  | "footer"
  | "header"
  | "li"
  | "main"
  | "nav"
  | "ol"
  | "section"
  | "span"
  | "ul";

export type FlexProps<T extends ElementType = "div"> = React.AriaAttributes &
  HTMLAttributes<T> &
  React.PropsWithChildren<{
    as?: Asable;
    center?: boolean;
    alignItems?: "center" | "end" | "start" | "stretch" | "baseline";
    justifyContent?:
      | "center"
      | "end"
      | "start"
      | "space-around"
      | "space-between"
      | "space-evenly"
      | "stretch";
    gap?: keyof typeof spacing | null;
    columnGap?: keyof typeof spacing | null;
    rowGap?: keyof typeof spacing | null;
    direction?: "row" | "column" | "row-reverse" | "column-reverse";
    wrap?: "nowrap" | "wrap" | "wrap-reverse";
    className?: string;
    inline?: boolean;
    style?: React.CSSProperties;
  }>;

export const Flex = forwardRef(
  <T extends ElementType = "div">(
    {
      className,
      style,
      as: Component = "div",
      role,
      center,
      alignItems,
      justifyContent,
      gap = "spacingMd",
      columnGap,
      rowGap,
      children,
      direction,
      wrap,
      inline,
      ...props
    }: FlexProps<T>,
    ref: Ref<any>
  ) => {
    const flexStyles = omitBy(
      {
        display: inline ? "inline-flex" : "flex",
        alignItems: center ? "center" : alignItems,
        justifyContent: center ? "center" : justifyContent,
        gap: gap && spacing[gap],
        columnGap: columnGap && spacing[columnGap],
        rowGap: rowGap && spacing[rowGap],
        flexDirection: direction,
        flexWrap: wrap,
        ...style
      },
      isNil
    );

    return (
      <Component className={className} style={flexStyles} role={role} ref={ref} {...(props as any)}>
        {children}
      </Component>
    );
  }
);

Flex.displayName = "Flex";
